<template>
  <!--begin::Proposal Listing-->
  <div class="proposal-template">
    <PageHeaderCount
      moduleType="proposal"
      :dataLoading.sync="dataLoading"
      :statusList.sync="statusList"
      allkey="all_proposals"
      countkey="proposal_status_count"
    ></PageHeaderCount>
    <ListingTemplate :customClass="'proposal-listing'">
      <template v-slot:body>
        <ListingHeader>
          <template v-slot:content>
            <template v-if="selectedRows.length > 0">
              <v-col cols="4" class="pt-0">
                <h1
                  class="form-title custom-nowrap-ellipsis margin-auto selected-rows-text"
                >
                  {{ totalSelected }} Selected
                </h1>
              </v-col>
              <v-col cols="8" class="pt-0 justify-flex-end d-flex margin-auto">
                <template v-if="getPermission('proposal:update')">
                  <v-menu
                    content-class="custom-menu-list"
                    bottom
                    offset-y
                    :close-on-click="true"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :disabled="dataLoading"
                        class="mx-2 custom-grey-border custom-bold-button"
                        color="cyan white--text"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark left>mdi-chevron-down</v-icon>Bulk Action
                      </v-btn>
                    </template>
                    <v-list>
                      <template v-for="(item, index) in bulkActions">
                        <v-list-item
                          link
                          v-on:click="bulkAction(item.action)"
                          :key="index"
                        >
                          <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                            <v-icon class="icon-default-blue">{{
                              item.icon
                            }}</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title
                            class="font-weight-500 font-size-14"
                            >{{ item.title }}</v-list-item-title
                          >
                        </v-list-item>
                      </template>
                    </v-list>
                  </v-menu>
                </template>
                <v-btn
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="red lighten-1 white--text"
                  v-on:click="clearSelections"
                >
                  <v-icon dark left>mdi-close</v-icon>Clear Selections
                </v-btn>
              </v-col>
            </template>
            <template v-else>
              <v-col cols="4" class="pt-0">
                <h1 class="form-title d-flex margin-auto">
                  <v-select
                    :disabled="dataLoading"
                    :items="statusList"
                    v-model="status"
                    hide-details
                    item-color="cyan"
                    class="pt-0 mt-0 listing-status main-listing-status-filter"
                    item-text="text"
                    item-value="value"
                  >
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.text"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-text="item.description"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-chip
                          :color="item.color"
                          :text-color="item.textcolor"
                        >
                          <template v-if="item.value == 'all'">{{
                            item.all_proposals
                          }}</template>
                          <template v-else>{{
                            item.proposal_status_count
                          }}</template>
                        </v-chip>
                      </v-list-item-action>
                    </template>
                  </v-select>
                </h1>
              </v-col>
              <v-col cols="8" class="pt-0 justify-flex-end d-flex margin-auto">
                <template v-if="getPermission('proposal:create')">
                  <v-btn
                    :disabled="dataLoading"
                    class="mx-2 custom-bold-button"
                    :to="getDefaultRoute('proposal.create')"
                    color="cyan white--text"
                  >
                    <v-icon dark left>mdi-plus</v-icon> Create
                  </v-btn>
                </template>
                <v-btn
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="cyan white--text"
                  v-on:click="searchDialog = true"
                >
                  <v-icon dark>mdi-filter</v-icon>
                </v-btn>
                <v-btn
                  v-if="!isEngineerChannel()"
                  v-on:click="
                    $router.push(
                      getDefaultRoute('profile.setting', {
                        query: {
                          tab: 'proposal'
                        }
                      })
                    )
                  "
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="cyan white--text"
                >
                  <v-icon dark>mdi-cog</v-icon>
                </v-btn>
                <v-menu content-class="custom-menu-list" offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="dataLoading || exportLoading"
                      class="mx-2 custom-bold-button"
                      color="cyan white--text"
                      v-bind="attrs"
                      v-on="on"
                      :loading="exportLoading"
                    >
                      <v-icon dark>mdi-menu</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <template v-for="(item, index) in moreActions">
                      <v-list-item
                        :key="index"
                        link
                        :class="{ 'line-active': item.sort }"
                        v-on:click="moreAction(item.action, item.sort)"
                      >
                        <v-list-item-title>
                          <v-icon v-if="item.icon" small left color="cyan">{{
                            item.icon
                          }}</v-icon>
                          {{ item.title }}
                          <template v-if="item.sort">
                            <v-icon
                              v-if="item.sort == 'asc'"
                              small
                              right
                              color="cyan"
                              >mdi-sort-ascending</v-icon
                            >
                            <v-icon
                              v-if="item.sort == 'desc'"
                              small
                              right
                              color="cyan"
                              >mdi-sort-descending</v-icon
                            >
                          </template>
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-menu>
                <v-menu
                  max-height="400"
                  max-width="250"
                  offset-y
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="dataLoading"
                      class="mx-2 custom-bold-button"
                      color="cyan white--text"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon dark>mdi-table-edit</v-icon>
                    </v-btn>
                  </template>
                  <draggable
                    tag="ul"
                    v-model="defaultColDefs"
                    class="draggable-group"
                    handle=".draggable-drag-icon"
                  >
                    <template v-for="cols in defaultColDefs">
                      <li
                        class="draggable-group-item"
                        :key="cols.field"
                        v-if="!cols.checkbox"
                      >
                        <v-checkbox
                          dense
                          v-model="defaultColShow"
                          v-bind:value="cols.field"
                          :label="cols.headerName"
                          :disabled="cols.fixed || dataLoading"
                          color="cyan"
                          hide-details
                          class="pt-2 mt-0"
                        ></v-checkbox>
                        <v-icon
                          class="draggable-drag-icon"
                          small
                          right
                          color="cyan"
                          >mdi-drag</v-icon
                        >
                      </li>
                    </template>
                  </draggable>
                </v-menu>
                <PageTips module="proposal"></PageTips>
              </v-col>
            </template>
          </template>
        </ListingHeader>
        <ListingSearchCriteria
          v-if="searchEnabled"
          pageModule="Proposal"
          :basicSearchFields="['barcode', 'reference', 'subject']"
          :allowedSearchFields="searchableArray"
        ></ListingSearchCriteria>
        <!-- <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll custom-box-top-inner-shadow"
          style="max-height: 60vh; position: relative"
        > -->
        <ListingTable
          :columnCount.sync="defaultColDefs.length"
          :dataLoading.sync="dataLoading"
          :rowData.sync="rowData"
        >
          <template v-slot:thead>
            <thead>
              <tr>
                <template v-for="cols in defaultColDefs">
                  <th
                    v-if="cols.visible"
                    :key="cols.id"
                    class="simple-table-th"
                    :class="{ 'checkbox-width-limit': cols.checkbox }"
                    :style="{ 'max-width': cols.width }"
                  >
                    <template v-if="cols.checkbox">
                      <v-checkbox
                        dense
                        :disabled="dataLoading"
                        v-model="selectedAll"
                        color="cyan"
                        class="hide-margin"
                        hide-details
                      ></v-checkbox>
                    </template>
                    <template v-else>
                      <div
                        v-if="cols.sortable"
                        class="simple-table-text sortable"
                        v-on:click="
                          !dataLoading
                            ? updateSorting({
                                field: cols.field,
                                sort: cols.sort
                              })
                            : 'javascript:void(0)'
                        "
                      >
                        {{ cols.headerName }}
                      </div>
                      <div v-else class="simple-table-text">
                        {{ cols.headerName }}
                      </div>
                      <template v-if="cols.sort">
                        <v-icon
                          class="simple-table-sort"
                          v-if="cols.sort == 'asc'"
                          small
                          right
                          color="cyan"
                          >mdi-sort-ascending</v-icon
                        >
                        <v-icon
                          class="simple-table-sort"
                          v-if="cols.sort == 'desc'"
                          small
                          right
                          color="cyan"
                          >mdi-sort-descending</v-icon
                        >
                      </template>
                    </template>
                  </th>
                </template>
              </tr>
            </thead>
          </template>
          <template v-slot:tbody>
            <tbody class="custom-border-bottom">
              <template v-if="!dataLoading">
                <template v-if="!lodash.isEmpty(rowData)">
                  <tr v-for="(data, index) in rowData" :key="index" link>
                    <template v-for="cols in defaultColDefs">
                      <td
                        v-if="cols.visible"
                        :key="cols.id"
                        :class="{
                          'simple-table-td': !cols.checkbox,
                          'checkbox-width-limit': cols.checkbox
                        }"
                        :style="{ 'max-width': cols.width }"
                        v-on:click="
                          !cols.checkbox
                            ? rowClicked(data)
                            : 'javascript:void(0)'
                        "
                      >
                        <template v-if="cols.checkbox">
                          <v-checkbox
                            dense
                            v-model="selectedRows"
                            v-bind:value="data.id"
                            color="cyan"
                            class="hide-margin"
                            hide-details
                          ></v-checkbox>
                        </template>
                        <template v-else-if="cols.field == 'barcode'">
                          <Barcode :barcode="data.barcode"></Barcode>
                        </template>
                        <template v-else-if="cols.field == 'basic_detail'">
                          <div class="proposal-listing">
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Name: </b>{{ data.name }}
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Company: </b>{{ data.company }}
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Email: </b>{{ data.email }}
                            </p>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'status'">
                          <CustomStatus
                            endpoint="proposals/status"
                            small
                            :status.sync="data.status"
                          ></CustomStatus>
                        </template>
                        <template v-else-if="cols.field == 'dates'">
                          <div class="proposal-listing">
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Proposal Date: </b>
                              <template v-if="data.proposal_date">{{
                                formatDate(data.proposal_date)
                              }}</template>
                              <template v-else
                                ><em class="text--secondary"
                                  >No Proposal Date</em
                                ></template
                              >
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Assigned Date: </b>
                              <template v-if="data.date_assigned">{{
                                formatDate(data.date_assigned)
                              }}</template>
                              <template v-else
                                ><em class="text--secondary"
                                  >No Assigned Date</em
                                ></template
                              >
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Converted Date: </b>
                              <template v-if="data.date_converted">{{
                                formatDate(data.date_converted)
                              }}</template>
                              <template v-else
                                ><em class="text--secondary"
                                  >No Converted Date</em
                                ></template
                              >
                            </p>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'assigned_user'">
                          <div
                            v-if="lodash.isEmpty(data.assigned_user) === false"
                            class="d-flex"
                          >
                            <v-tooltip
                              :key="index"
                              top
                              content-class="custom-top-tooltip"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-avatar v-bind="attrs" v-on="on">
                                  <v-img
                                    :lazy-src="$defaultProfileImage"
                                    :src="
                                      $assetAPIURL(
                                        data.assigned_user.profile_logo
                                      )
                                    "
                                  ></v-img>
                                </v-avatar>
                              </template>
                              <span>{{ data.assigned_user.full_name }}</span>
                            </v-tooltip>
                          </div>
                          <template v-else>
                            <em class="text--secondary">No User Assigned</em>
                          </template>
                        </template>
                        <template v-else-if="cols.field == 'added_at'">
                          <TableActivity
                            v-if="!lodash.isEmpty(data.added_by)"
                            :data.sync="data"
                          >
                            <template v-slot:display_name>
                              {{ data.added_by.display_name }}
                            </template>
                            <template v-slot:date_time>
                              {{ data.created_at }}
                            </template>
                            <template v-slot:format_date_time>
                              {{ formatDateTime(data.added_at) }}
                            </template>
                          </TableActivity>
                        </template>
                        <template v-else-if="cols.field == 'updated_at'">
                          <TableActivity
                            v-if="!lodash.isEmpty(data.updated_by)"
                            :data.sync="data"
                          >
                            <template v-slot:display_name>
                              {{ data.updated_by.display_name }}
                            </template>
                            <template v-slot:date_time>
                              {{ data.modified_at }}
                            </template>
                            <template v-slot:format_date_time>
                              {{ formatDateTime(data.updated_at) }}
                            </template>
                          </TableActivity>
                        </template>
                        <template v-else>
                          <div v-html="printRowData(cols, data)"></div>
                        </template>
                      </td>
                    </template>
                  </tr>
                </template>
                <tr v-else-if="!dataLoading">
                  <td :colspan="defaultColDefs.length" class="py-4">
                    <p
                      class="m-0 row-not-found text-center font-weight-500 font-size-16"
                    >
                      <img
                        :src="$assetURL('media/error/empty.png')"
                        class="row-not-found-image"
                      />
                      Uhh... There are no proposal at the moment.
                    </p>
                  </td>
                </tr>
              </template>
            </tbody>
          </template>
        </ListingTable>
        <!-- </perfect-scrollbar> -->
        <ListingFooter
          :dataLoading.sync="dataLoading"
          :showingFrom.sync="showingFrom"
          :showingTo.sync="showingTo"
          :totalRows.sync="totalRows"
          :currentPage.sync="currentPage"
          :totalPages.sync="totalPages"
        ></ListingFooter>
        <Dialog :commonDialog="searchDialog">
          <template v-slot:title>Filter Proposal</template>
          <template v-slot:body>
            <!-- <perfect-scrollbar
              :options="{ suppressScrollX: true }"
              class="scroll custom-box-top-inner-shadow"
              style="max-height: 60vh; position: relative"
            > -->
            <v-container fluid class="pt-0 custom-search-filter">
              <v-row>
                <v-col md="6">
                  <v-menu
                    v-model="datePicker"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        dense
                        filled
                        flat
                        label="Dates Range"
                        hide-details
                        solo
                        clearable
                        v-on:click:clear="dates = []"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :value="formattedDate"
                        color="cyan"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      color="cyan"
                      range
                      v-model="dates"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <template v-for="(field, index) in searchableArray">
                  <v-col md="6" :key="index" v-if="field != 'dates'">
                    <v-text-field
                      v-model.trim="listingSearch[field]"
                      dense
                      filled
                      hide-details
                      :label="getFilterLabel(field, 'Proposal')"
                      solo
                      flat
                      clearable
                      @keydown.enter="filterRows"
                      color="cyan"
                    ></v-text-field>
                  </v-col>
                </template>
              </v-row>
            </v-container>
            <!-- </perfect-scrollbar> -->
          </template>
          <template v-slot:action>
            <v-btn
              :disabled="dataLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              color="cyan white--text"
              v-on:click="filterRows"
            >
              Filter
            </v-btn>
            <v-btn
              :disabled="dataLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              v-on:click="searchDialog = false"
            >
              Close
            </v-btn>
          </template>
        </Dialog>
      </template>
    </ListingTemplate>
  </div>
  <!--end::Proposal Listing-->
</template>

<script>
import draggable from "vuedraggable";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingMixin from "@/core/plugins/listing-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { PATCH } from "@/core/services/store/request.module";
import Barcode from "@/view/pages/partials/Barcode.vue";
import TableActivity from "@/view/pages/partials/Table-Activity.vue";
import ListingTemplate from "@/view/pages/partials/Listing-Template.vue";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import ListingHeader from "@/view/pages/partials/Listing-Header.vue";
import ListingFooter from "@/view/pages/partials/Listing-Footer.vue";
import ListingSearchCriteria from "@/view/pages/partials/Listing-Search-Criteria.vue";
import Dialog from "@/view/pages/partials/Dialog.vue";
import PageTips from "@/view/layout/extras/offcanvas/PageTips.vue";
import PageHeaderCount from "@/view/pages/partials/PageHeaderCount.vue";
import CustomStatus from "@/view/pages/partials/CustomStatus";

export default {
  mixins: [CommonMixin, ListingMixin],
  name: "proposal-list",
  data() {
    return {
      exportLoading: false,
      dates: [],
      pageModule: "proposal-listing",
      routeAPI: "proposals",
      routeName: "proposal",
      routeDetailName: "proposal.detail",
      status: "all",
      statusList: [],
      moreActions: [
        /*{
          title: "Export Proposal(s)",
          action: "export_items",
          divider: false,
          icon: "mdi-database-export"
        },
        {
          title: "Export Current View",
          action: "export_current_view",
          divider: true,
          icon: "mdi-database-export"
        },*/
        {
          title: "Refresh List",
          action: "refresh_list",
          divider: true,
          icon: "mdi-refresh"
        }
      ],
      bulkActions: [
        {
          title: "Mark as Follow UP",
          action: "follow_up",
          icon: "mdi-check-all"
        },
        {
          title: "Mark as 1st Meeting",
          action: "1st_meeting",
          icon: "mdi-check-all"
        },
        {
          title: "Mark as Proposal",
          action: "proposal",
          icon: "mdi-check-all"
        },
        {
          title: "Mark as Quotation",
          action: "quotation",
          icon: "mdi-check-all"
        },
        {
          title: "Mark as 2nd Meeting More",
          action: "2nd_meeting_more",
          icon: "mdi-check-all"
        },
        {
          title: "Mark as KW Send Out",
          action: "kw_send_out",
          icon: "mdi-check-all"
        },
        {
          title: "Mark as Rejected",
          action: "reject",
          icon: "mdi-check-all"
        },
        {
          title: "Mark as Accepted",
          action: "accept",
          icon: "mdi-check-all"
        },
        {
          title: "Mark as Resend Quote",
          action: "resend_quote",
          icon: "mdi-check-all"
        },
        {
          title: "Mark as On Hold",
          action: "on_hold",
          icon: "mdi-check-all"
        },
        {
          title: "Mark as Waiting for Scope",
          action: "waiting_for_scope",
          icon: "mdi-check-all"
        }
        /*{ title: "Delete", action: "delete", icon: "mdi-delete" },
        { title: "Download PDF", action: "download_pdf", icon: "mdi-file-pdf" },
        { title: "Print", action: "print", icon: "mdi-printer" }*/
      ]
    };
  },
  watch: {
    dates: {
      deep: true,
      immediate: true,
      handler(param) {
        if (param.length > 1) {
          this.listingSearch["dates"] = param;
        } else {
          this.listingSearch["dates"] = undefined;
        }
      }
    }
  },
  components: {
    PageHeaderCount,
    draggable,
    CustomStatus,
    PageTips,
    Barcode,
    TableActivity,
    ListingTemplate,
    ListingFooter,
    ListingTable,
    ListingHeader,
    ListingSearchCriteria,
    Dialog
  },
  methods: {
    bulkAction(action) {
      const _this = this;
      switch (action) {
        case "follow_up":
          _this.updateProposalStatus(2);
          break;
        case "1st_meeting":
          _this.updateProposalStatus(3);
          break;
        case "proposal":
          _this.updateProposalStatus(4);
          break;
        case "quotation":
          _this.updateProposalStatus(5);
          break;
        case "2nd_meeting_more":
          _this.updateProposalStatus(6);
          break;
        case "kw_send_out":
          _this.updateProposalStatus(7);
          break;
        case "reject":
          _this.updateProposalStatus(8);
          break;
        case "accept":
          _this.updateProposalStatus(9);
          break;
        case "resend_quote":
          _this.updateProposalStatus(10);
          break;
        case "on_hold":
          _this.updateProposalStatus(11);
          break;
        case "waiting_for_scope":
          _this.updateProposalStatus(12);
          break;
      }
    },
    updateProposalStatus(status) {
      const _this = this;
      _this.dataLoading = true;
      _this.$store
        .dispatch(PATCH, {
          url: "proposals/status",
          data: { proposal: _this.selectedRows, status }
        })
        .then(() => {
          _this.getRows();
        })
        .catch(error => {
          _this.logError(error);
        })
        .finally(() => {
          _this.dataLoading = false;
        });
    },
    moreAction(action) {
      const _this = this;
      switch (action) {
        case "import_items":
          break;
        case "export_items":
          _this.ProposalExport();
          break;
        case "export_current_view":
          _this.exportCurrentView("proposal");
          break;
        case "refresh_list":
          _this.getRows();
          break;
      }
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Proposal"
      }
    ]);
  },
  beforeMount() {
    const _this = this;
    _this.lodash.assign(_this.queryParams, _this.$route.query);

    _this.defaultColDefs = [
      {
        headerName: "",
        field: "id",
        sort: null,
        visible: true,
        fixed: true,
        sortable: true,
        checkbox: true,
        order: 0,
        width: "50px"
      },
      {
        headerName: "Proposal #",
        field: "barcode",
        sort: null,
        visible: true,
        fixed: false,
        sortable: true,
        order: 1,
        width: "200px"
      },
      {
        headerName: "Basic Details",
        field: "basic_detail",
        sort: null,
        visible: true,
        fixed: false,
        sortable: false,
        order: 2,
        width: "300px"
      },
      {
        headerName: "Dates",
        field: "dates",
        sort: null,
        visible: true,
        fixed: false,
        sortable: false,
        order: 4,
        width: "300px"
      },
      {
        headerName: "Assigned User",
        field: "assigned_user",
        sort: null,
        visible: true,
        fixed: false,
        sortable: false,
        order: 5,
        width: "300px"
      },
      {
        headerName: "Status",
        field: "status",
        sort: null,
        visible: true,
        fixed: false,
        sortable: true,
        order: 3,
        width: "200px"
      },
      {
        headerName: "Created Time",
        child: "created_at",
        field: "added_at",
        sort: null,
        visible: true,
        fixed: false,
        sortable: true,
        order: 6,
        width: "170px"
      },
      {
        headerName: "Last Modified Time",
        child: "modified_at",
        field: "updated_at",
        sort: null,
        visible: true,
        fixed: false,
        sortable: true,
        order: 7,
        width: "170px"
      }
    ];

    _this.defaultColShow = _this.defaultColDefs.map(col => col.field);

    _this.status = _this.$route.query.status || _this.status;

    _this.applyColState();

    _this.paginationPageSize =
      window.localStorage.getItem(_this.pageModule) || 10;
  }
};
</script>
